import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import SEO from 'components/shared/SEO';
import Header from 'components/Header/Header';
import About from 'components/About/About';
import Offer from 'components/Offer/Offer';
import Certificates from 'components/Certificates/Certificates';
import WhySAP from 'components/WhySAP/WhySAP';
import Competences from 'components/Competences/Competences';
import Contact from 'components/Contact/Contact';
import Career from 'components/Career/Career';
import OurTeam from 'components/OurTeam/OurTeam';

import { teamMembersEN } from 'assets/data/team';
import slugify from 'slugify';

const IndexPageEn = ({ data }) => {
  const heroImg = data.heroImg.childImageSharp.fluid;
  const card1Img = data.card1Img.childImageSharp.fluid;
  const card2Img = data.card2Img.childImageSharp.fluid;
  const card3Img = data.card3Img.childImageSharp.fluid;
  const certificateImg = data.certificateImg.childImageSharp.fluid;
  const certificatePic = data.certificatePicImg.childImageSharp.fluid;
  const certificatePrince = data.certificatePrinceImg.childImageSharp.fluid;
  const jobOffers = data.allWpPost.edges;

  const slides = [
    `<b>SAP</b> system<br/> a strategy for growth`,
    `<b>Aseti</b> - professional in business, effective in action`,
    `<b>Aseti</b> -the power <br/>of experience broad competence`,
    `<b>Aseti</b> - the higher<br/>level of business`,
  ];

  const cards = [
    {
      image: card1Img,
      imageAlt: 'card',
      description:
        'Years of experience in SAP ERP implementation for various industries',
    },
    {
      image: card3Img,
      imageAlt: 'card',
      description: 'Team working ability',
    },
    {
      image: card2Img,
      imageAlt: 'card',
      description: 'Clear and understandable communication with The Business',
    },
  ];

  const offerCards = [
    {
      icon: data.userIcon.publicURL,
      iconAlt: 'user icon',
      description: 'SAP implementations and rollout',
    },
    {
      icon: data.likeIcon.publicURL,
      iconAlt: 'like icon',
      description: 'Integration with usage of SAP PI solution',
    },
    {
      icon: data.groupIcon.publicURL,
      iconAlt: 'group icon',
      description: 'Hardware and database migration on new platforms',
    },
    {
      icon: data.internetIcon.publicURL,
      iconAlt: 'internet icon',
      description:
        'SAP and external solutions integration<span>(webservice, idoc (EDI))</span>',
    },
    {
      icon: data.workIcon.publicURL,
      iconAlt: 'work icon',
      description: 'SAP implementation audits',
    },
    {
      icon: data.clockIcon.publicURL,
      iconAlt: 'clock icon',
      description: 'SAP application service',
    },
    {
      icon: data.laptopIcon.publicURL,
      iconAlt: 'laptop icon',
      description: 'SAP upgrades',
    },
    {
      icon: data.serverIcon.publicURL,
      iconAlt: 'server icon',
      description: 'SAP Basis (Administraton)',
    },
  ];

  const competencesCards = [
    {
      icon: data.competence3Icon.publicURL,
      iconAlt: 'icon',
      description: 'Financial accounting <span>(SAP FI)</span>',
    },
    {
      icon: data.competence2Icon.publicURL,
      iconAlt: 'icon',
      description: 'Fixed asset accounting <span>(SAP FI -AA)</span>',
    },
    {
      icon: data.competence1Icon.publicURL,
      iconAlt: 'icon',
      description: 'Cash management <span>(SAP FI-TR)</span>',
    },
    {
      icon: data.competence5Icon.publicURL,
      iconAlt: 'icon',
      description: 'Controlling <span>(SAP CO)</span>',
    },
    {
      icon: data.competence4Icon.publicURL,
      iconAlt: 'icon',
      description: 'Investment management <span>(SAP IM)</span>',
    },
    {
      icon: data.competence2Icon.publicURL,
      iconAlt: 'icon',
      description: 'Projects management <span>(SAP PS)</span>',
    },
    {
      icon: data.competence2Icon.publicURL,
      iconAlt: 'icon',
      description: 'Materials management, procurement <span>(SAP MM)</span>',
    },
    {
      icon: data.competence1Icon.publicURL,
      iconAlt: 'icon',
      description:
        'Warehouse management, warehouse management <span>(SAP MM, SAP WMS)</span>',
    },
    {
      icon: data.competence5Icon.publicURL,
      iconAlt: 'icon',
      description: 'Sales and distribution <span>(SAP SD)</span>',
    },
    {
      icon: data.competence4Icon.publicURL,
      iconAlt: 'icon',
      description: 'Production planning <span>(SAP PP)</span>',
    },
    {
      icon: data.competence2Icon.publicURL,
      iconAlt: 'icon',
      description: 'Quality management <span>(SAP QM)</span>',
    },
    {
      icon: data.competence3Icon.publicURL,
      iconAlt: 'icon',
      description: 'Maintenance <span>(SAP PM)</span>',
    },
  ];

  const certSections = [
    {
      section: 'Infrastructure & Administration',
      certificates: [
        {
          name: `SAP Certified Technology Consultant SAP NetWeaver '04 - SAP Web AS for Oracle`,
          image: certificateImg,
        },
        {
          name: `SAP Certified Technology Associate - OS/DB Migration for SAP NetWeaver 7.52`,
          image: certificateImg,
        },
        {
          name: `SAP Certified Application Associate - SAP Mobile Platform Native and Hybrid Application Administration (SMP 2.3)`,
          image: certificateImg,
        },
        {
          name: `SAP Certified Support Associate - Incident Management with SAP BusinessObjects `,
          image: certificateImg,
        },
        {
          name: `SAP Certified Support Associate - SAP HANA`,
          image: certificateImg,
        },
        {
          name: `Support Consultant for Incident Management with SAP Business All-in-One`,
          image: certificateImg,
        },
      ],
    },
    {
      section: 'Application - Finance & Controlling',
      certificates: [
        {
          name: 'Certyfikat Management Accounting (CO) SAP ERP',
          image: certificateImg,
        },
        {
          name: 'SAP Certified Application Associate - Financial Accounting with SAP ERP 6.0 EhP7',
          image: certificateImg,
        },
        {
          name: 'SAP Certified Application Associate - Financial Accounting with SAP ERP 6.0 EHP4',
          image: certificateImg,
        },
        { name: 'MySAP Financials – ERP', image: certificatePic },
        { name: 'Szkoła Controllingu V-IX 2000', image: certificatePic },
      ],
    },
    {
      section: 'Application - Logistics',
      certificates: [
        {
          name: 'SAP Certified Application Associate - Order Fulfillment with SAP ERP 6.0 EHP5',
          image: certificateImg,
        },
        {
          name: 'SAP Solution Consultant SCM - Order Fulfillment with mySAP ERP',
          image: certificateImg,
        },
        {
          name: 'Application Consultant ‘Sales and Distribution’ with mySAP.com. Certification2000',
          image: certificateImg,
        },
        {
          name: 'Solution Consultant SCM - Procurement with my SAP ERP',
          image: certificateImg,
        },
      ],
    },
    {
      section: 'Application - Human Capital Management',
      certificates: [
        {
          name: 'SAP Certified Application Associate - Human Capital Management with SAP ERP 6.0 EHP5',
          image: certificateImg,
        },
      ],
    },
    {
      section: 'Integration & Development',
      certificates: [
        {
          name: 'SAP Certified Development Associate - Process Integration with SAP NetWeaver (PI 7.3)',
          image: certificateImg,
        },
        {
          name: 'SAP Certified Development Associate - ABAP with SAP NetWeaver 7.50',
          image: certificateImg,
        },
        {
          name: 'SAP Certified Development Consultant SAP NetWeaver 2004 - Application Development Focus ABAP',
          image: certificateImg,
        },
      ],
    },
    {
      section: 'Project Management',
      certificates: [
        {
          name: 'Prince2 Foundation (APM Group) 2012',
          image: certificatePrince,
        },
        {
          name: 'Prince2 Practitioner (APM Group) 2011',
          image: certificatePrince,
        },
      ],
    },
  ];

  const TEAM_MEMBERS = useMemo(
    () =>
      teamMembersEN.map(member => ({
        name: member.name,
        surname: member.surname,
        slug: slugify(`${member.name}-${member.surname}`, {
          lower: true,
        }),
        img: data[`teamMember${member.id}`].childImageSharp.fluid,
        linkedin: member.linkedin,
        role: member.role,
      })),
    []
  );

  return (
    <>
      <SEO
        title="Home"
        description="Many years of work implementing the SAP system have enabled us to build up extensive know-how, thanks to which we can offer comprehensive solutions tailored to the needs of our clients from any industry."
      />
      <div id="home" />
      <Header
        image={heroImg}
        imageAlt="banner image"
        slides={slides}
        buttonText="Ask for offer"
      />
      <main>
        <About
          heading="About <b>us</b>"
          cards={cards}
          description1="Experienced, professional, efficient – that is how our team of consultants, programmers and project managers could be shortly described. Many years of performing SAP implementations have allowed us to build the know-how that allows us to offer comprehensive solutions tailored for the needs of our Customers from diverse industries such as:  Banking, Power engineering, Pharmaceuticals, Finances, FMCG, Trade, Logistics Automotive, Healthcare, Chemical, Electromechanical, Fuel, Manufacturing, Mining, TMT – telecommunication, Media and technologies, Transport, Insurance, Services"
          description2="Efficient implementation of ERP systems requires us to have a broad knowledge of IT solutions and their configuration, and  a deep knowledge of business processes  in accounting, finance, controlling, logistics and production.<br/><br/>As well as having many years of experience with SAP ERP and SAP S/4 HANA implementations, all of our consultants are SAP certified. Our team also includes experienced ABAP programmers."
        />
        <Competences heading="Our <b>expertise</b>" cards={competencesCards} />
        <OurTeam heading="Meet <b>our team</b>" members={TEAM_MEMBERS} />
        <Certificates heading="Certificate" sections={certSections} />
        <WhySAP
          heading="Why <b>SAP?</b>"
          description="For efficient strategy realisation, each company needs tools that support reaching its goals. Advanced IT systems from SAP A.G. company are such tools. They give new possibilities of successful business decisions, increase efficiency of activities and allow significant reduction of company costs. Easy and intuitive interface, better data exchange, automation of repetitive activities – that are characteristics valued by employees using SAP solutions.<br/><br/> SAP A.G. is counted as an ERP systems market leader, and S/4 HANA as unquestioned number one application in the world. Its popularity owes to great functional possibilities, configuration flexibility and modern technology. Users of SAP ERP and SAP S/4 HANA emphasise also the security of the solutions, various possibilities of integration and cooperation with external software and its scalability and flexibility."
        />
        <Offer
          heading="Our offer – what could we do for <b>you</b>"
          cards={offerCards}
        />
        {jobOffers.length && <Career heading="Job offers" cards={jobOffers} />}
        <Contact
          header={{
            title: 'Mission',
            description:
              'Provide the highest quality service in implementation, development and support of SAP ERP systems for our clients.',
          }}
          contactHeading="Contact <b>us</b>"
          contactData="<b>Aseti Sp. z o.o.</b><br/><br/>Marynarska St.<br/>02-674 Warsaw<br/><br/>NIP 5252821667<br/>REGON 385964076"
          contactEmail="biuro@aseti.pl"
        />
      </main>
    </>
  );
};

export const query = graphql`
  {
    heroImg: file(name: { eq: "hero-img" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    card1Img: file(name: { eq: "card3" }) {
      childImageSharp {
        fluid(maxWidth: 350, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    card2Img: file(name: { eq: "card1" }) {
      childImageSharp {
        fluid(maxWidth: 350, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    card3Img: file(name: { eq: "card2" }) {
      childImageSharp {
        fluid(maxWidth: 350, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    certificateImg: file(name: { eq: "certificate" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    certificatePicImg: file(name: { eq: "certificate-pic" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    certificatePrinceImg: file(name: { eq: "certificate-prince" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    teamMember1: file(name: { eq: "team-member-1" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    teamMember2: file(name: { eq: "team-member-2" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    teamMember3: file(name: { eq: "team-member-3" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    teamMember4: file(name: { eq: "team-member-4" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    teamMember5: file(name: { eq: "team-member-5" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    teamMember6: file(name: { eq: "team-member-6" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    teamMember7: file(name: { eq: "team-member-7" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    teamMember8: file(name: { eq: "team-member-8" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    teamMember9: file(name: { eq: "team-member-9" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    teamMember10: file(name: { eq: "team-member-10" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    userIcon: file(name: { eq: "user" }) {
      publicURL
    }
    likeIcon: file(name: { eq: "like" }) {
      publicURL
    }
    groupIcon: file(name: { eq: "group" }) {
      publicURL
    }
    internetIcon: file(name: { eq: "internet" }) {
      publicURL
    }
    workIcon: file(name: { eq: "work-shedule" }) {
      publicURL
    }
    clockIcon: file(name: { eq: "wall-clock" }) {
      publicURL
    }
    laptopIcon: file(name: { eq: "laptop" }) {
      publicURL
    }
    serverIcon: file(name: { eq: "server" }) {
      publicURL
    }
    competence1Icon: file(name: { eq: "competence1" }) {
      publicURL
    }
    competence2Icon: file(name: { eq: "competence2" }) {
      publicURL
    }
    competence3Icon: file(name: { eq: "competence3" }) {
      publicURL
    }
    competence4Icon: file(name: { eq: "competence4" }) {
      publicURL
    }
    competence5Icon: file(name: { eq: "competence5" }) {
      publicURL
    }
    allWpPost {
      edges {
        node {
          jobOffer {
            jobCity
            jobOfferEnding
            jobOfferIntro
            jobOfferPosition
            jobPosition
            jobOfferNicetosee {
              jobOfferNicetoseeSingle
            }
            jobOfferOuroffer {
              jobOfferOurofferSingle
            }
            jobOfferRequirements {
              jobOfferRequirementsSingle
            }
            jobOfferResponsibilities {
              jobOfferResponsibilitesSingle
            }
          }
        }
      }
    }
  }
`;

IndexPageEn.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default IndexPageEn;
